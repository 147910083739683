
import { defineComponent, reactive } from "vue";

export default defineComponent({
  setup() {
    const state = reactive({
      email: "",
    });

    return {
      state,
    };
  },

  methods: {
    submitForm() {
      console.log("Email: " + this.state.email);
    },
  },
});
