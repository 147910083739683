import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-gradient-to-t from-primary-100 to-green-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navbar = _resolveComponent("navbar")!
  const _component_hero_header = _resolveComponent("hero-header")!
  const _component_explainer_panel = _resolveComponent("explainer-panel")!
  const _component_email_contact = _resolveComponent("email-contact")!
  const _component_footer_panel = _resolveComponent("footer-panel")!

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_navbar, { extraClasses: "bg-opacity-10" }),
      _createVNode(_component_hero_header)
    ]),
    _createVNode(_component_explainer_panel),
    _createVNode(_component_email_contact),
    _createVNode(_component_footer_panel)
  ]))
}