
import { Vue } from "vue-class-component";

export default class ExplainerPanel extends Vue {
  data() {
    return {
      cards: [
        {
          title: "Create your profile for free.",
          svgUrl: require("@/assets/img/explainer/start_profile.svg"),
          animation: "fade-right",
        },
        {
          title: "Link your social media accounts.",
          svgUrl: require("@/assets/img/explainer/configure_profile.svg"),
          animation: "fade-in",
        },
        {
          title: "Connect with brands.",
          svgUrl: require("@/assets/img/explainer/connect_w_brands.svg"),
          animation: "fade-left",
        },
      ],
    };
  }
}
