
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      columns: [
        {
          title: "Creatory.xyz",
          links: [
          ],
        },
        {
          title: "Navigation",
          links: [
            { text: "About", url: "/" },
            { text: "Creators", url: "/" },
            { text: "Brands", url: "/" },
          ],
        },
        {
          title: "Creators",
          links: [
            { text: "Sign in", url: "/" },
            { text: "Create profile", url: "/" },
          ],
        },
        {
          title: "Businesses",
          links: [
            { text: "Get started", url: "/" },
            { text: "Sign in", url: "/" },
            { text: "Sign up", url: "/" },
          ],
        },
      ],
    };
  },
});
