import { GetterTree  } from "vuex";

import { State } from "@/store/state";
import { UserData } from "@/model/user";

export type Getters = {
    getUserData(state: State): UserData | undefined;
}

export const getters: GetterTree<State, State> & Getters = {
    getUserData(state: State): UserData | undefined {
        return state.userData
    }
}