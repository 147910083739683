
import { defineComponent } from "vue";
import { Auth } from "aws-amplify";

export default defineComponent({
  props: ["extraClasses"],
  data() {
    return {
      isMobileMenuHidden: true,
      isUserLoggedIn: false,
      menuLinks: [
        { text: "About", url: "/" },
        { text: "Creators", url: "/" },
        { text: "Brands", url: "/" },
      ],
    };
  },

  mounted() {
    this.checkUserStatus();
  },

  methods: {
    signOut() {
      Auth.signOut().then(() => {
        this.checkUserStatus();
        this.$router.push("/");
      });
    },

    checkUserStatus() {
      Auth.currentAuthenticatedUser()
        .then(() => {
          this.isUserLoggedIn = true;
        })
        .catch(() => {
          this.isUserLoggedIn = false;
        });
    },
  },
});
