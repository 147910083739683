import { MutationTree } from "vuex";

import { State } from "@/store/state";
import { UserData } from "@/model/user";

export enum MutationType {
    SetUserData = "SET_USER_DATA",
    SetLoading = "SET_LOADING",
}

export type Mutations = {
    [MutationType.SetUserData](state: State, userData: UserData | undefined): void
    [MutationType.SetLoading](state: State, value: boolean): void
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationType.SetUserData](state, userData) {
        state.userData = userData;
    },
    [MutationType.SetLoading](state, value) {
        state.loading = value;
    }
}