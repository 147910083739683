import { ActionContext, ActionTree } from "vuex";

import { Mutations, MutationType } from "@/store/mutations";
import { State } from "@/store/state";
import { UserData } from "@/model/user";
import { fetchUserData } from "@/model/user";

export enum ActionType {
  GetUserData = "GET_USER_DATA",
  InvalidateLocalUserData = "INVALIDATE_LOCAL_USER_DATA",
}

type ActionArguments = Omit<ActionContext<State, State>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionType.GetUserData](context: ActionArguments): Promise<void>;
  [ActionType.InvalidateLocalUserData](context: ActionArguments): Promise<void>;
};

export const actions: ActionTree<State, State> & Actions = {
  async [ActionType.GetUserData]({ commit }) {
    commit(MutationType.SetLoading, true);

    return fetchUserData()
      .then((userData) => {
        if (userData !== undefined) {
          commit(MutationType.SetUserData, userData);
        }

        commit(MutationType.SetLoading, false);
      })
      .finally(() => {
        commit(MutationType.SetLoading, false);
      });
  },
  async [ActionType.InvalidateLocalUserData]({ commit }) {
    commit(MutationType.SetUserData, undefined);
  }
};
