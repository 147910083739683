
import { Options, Vue } from "vue-class-component";

import mixpanel from "mixpanel-browser";

import HeroHeader from "@/components/HeroHeader.vue";
import Navbar from "@/components/Navbar.vue";
import ExplainerPanel from "@/components/ExplainerPanel.vue";
import EmailContact from "@/components/EmailContact.vue";
import FooterPanel from "@/components/Footer.vue";

@Options({
  components: {
    Navbar,
    HeroHeader,
    ExplainerPanel,
    EmailContact,
    FooterPanel,
  },
})
export default class App extends Vue {

  mounted() {
    mixpanel.track("Landing Page Visit");
  }

}
