import axios from "axios";

const httpClient = axios.create({
  baseURL: "https://jxr6hfts87.execute-api.us-east-1.amazonaws.com",
  headers: {
    "Content-type": "application/json",
  },
});

const httpPublicClient = axios.create({
  baseURL: "https://uxrdq9ow3e.execute-api.us-east-1.amazonaws.com/v1.0",
  headers: {
    "Content-type": "application/json",
  },
});

export {
  httpClient,
  httpPublicClient,
};
