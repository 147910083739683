import { createApp } from "vue";

import AOS from 'aos';
import 'aos/dist/aos.css';

import Amplify from "aws-amplify";

import Vuelidate from "@vuelidate/core";

import mixpanel from "mixpanel-browser";

import App from "@/App.vue";
import "@/assets/css/index.css";
import router from "@/router";
import { store } from "@/store";

mixpanel.init("bc642efec137fce9d5a897d6b974abc1", { debug: false });

Amplify.configure({
  Auth: {
    region: "us-east-1",
    userPoolId: "us-east-1_HuRlIuVPZ",
    userPoolWebClientId: "3tgo63c9qrjl1kbkpe7fql9cs4",
  },
});

AOS.init();

createApp(App).use(store).use(Vuelidate).use(router).mount("#app");
