
import { Vue } from "vue-class-component";

import mixpanel from "mixpanel-browser";

export default class HeroHeader extends Vue {
  createProfile() {
    mixpanel.track("CreateProfile clicked");
  }
}
