import { Auth } from "aws-amplify";

import { httpClient, httpPublicClient } from "@/model/httpUtils";

export interface QuickLink {
  Id: string;
  Ordering: number;
  DisplayText: string;
  Url: string;
}
export interface UserData {
  Id: string;
  CreatedAt: string;
  UpdatedAt: string;
  ProfileSetup: boolean;
  Email: string;
  Bio: {
    FirstName: string;
    LastName: string;
    ProfileHeading: string;
    ProfileDescription: string;
  };
  QuickLinks?: QuickLink[];
  Username?: string;
}

export interface UserProfileResp {
  statusCode: number;
  data: UserData | undefined;
}

async function fetchUserData(): Promise<UserData | undefined> {
  console.log("Loading User Data");

  const user = await Auth.currentAuthenticatedUser();

  const userData = await httpClient.get("/profile", {
    params: {
      userId: user.username,
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  });

  if (userData.status !== 200) {
    console.error("Couldn't fetch user data! Error: " + userData.data);
    return undefined;
  }

  if (userData.data === undefined) {
    console.error(
      "Some user data field is undefined! Returned data is: " +
        JSON.stringify(userData)
    );
    return undefined;
  }

  try {
    userData.data = JSON.parse(userData.data);
  } catch (err) {
    console.error("Failed to parse userData.data=" + userData.data);
    return undefined;
  }

  return userData.data as UserData;
}

async function createUserProfile(): Promise<void> {
  console.log("Creating User Profile");

  const user = await Auth.currentAuthenticatedUser();
  // console.log("Current user=" + JSON.stringify(user));
  // console.log("Current userId=" + JSON.stringify(user.username));
  // console.log("Current userId=" + JSON.stringify(user.attributes.email));
  const resp = await httpClient.post(
    "/profile",
    {},
    {
      params: {
        userId: user.username,
        userEmail: user.attributes.email,
      },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    }
  );
  if (resp.status !== 201) {
    console.error("Couldn't create user profile! Error: " + resp.data);
    return undefined;
  }
}

async function updateUserProfile(userData: UserData): Promise<void> {
  console.log("Updating User Profile");

  const user = await Auth.currentAuthenticatedUser();
  // console.log("Current user=" + JSON.stringify(user));
  // console.log("Current userId=" + JSON.stringify(user.username));
  // console.log("Current userId=" + JSON.stringify(user.attributes.email));
  const resp = await httpClient.put(
    "/profile",
    {},
    {
      params: {
        userData: userData,
      },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    }
  );
  if (resp.status !== 204) {
    console.error("Couldn't create user profile! Error: " + resp.data);
    return undefined;
  }
}

async function fetchPublicUserProfile(
  username: string
): Promise<UserProfileResp> {
  return httpPublicClient
    .get("/profile/" + username, {})
    .then((resp) => {
      try {
        return { statusCode: resp.status, data: JSON.parse(resp.data) };
      } catch (err) {
        console.error("Failed to parse userData.data=" + resp.data);
        return { statusCode: resp.status, data: undefined };
      }
    })
    .catch((err) => {
      console.error("Couldn't fetch user data! Error: " + err);
      return { statusCode: 404, data: undefined };
    });
}

export {
  fetchUserData,
  createUserProfile,
  updateUserProfile,
  fetchPublicUserProfile,
};
